//
// Theme Modes
//

// Used for theme mode switching via JS
[data-kt-theme-mode-switching="true"] * {
  transition: none !important;
}

// Light mode
@include theme-light() {
  .theme-dark-show {
    display: none !important;
  }

  .theme-light- {
    background-color: transparent !important;
  }

  .theme-light-bg-body {
    background-color: var(--kt-body-bg) !important;
  }
}

// Dark mode
@include theme-dark() {
  .theme-light-show {
    display: none !important;
  }

  .theme-dark- {
    background-color: transparent !important;
  }

  .theme-dark-bg-body {
    background-color: var(--kt-body-bg) !important;
  }
}